$primary-dark: #653208 !default;
$primary: #fd7e14 !default;
$primary-50: #eddc8f !default;
$primary-25: #f6edc7 !default;
$body-bg-dark: #3c4b64;
$sidebar-nav-link-active-bg: hsla(0, 0%, 100%, 0.1);
$sidebar-nav-link-hover-bg: hsla(0, 0%, 100%, 0.1);
$sidebar-nav-group-bg: rgba(0, 0, 0, 0.2);

$zindex-modal: 1960 !default;

$sidebar-nav-link-border-radius: 0;
$sidebar-nav-padding-y: 0;
$sidebar-nav-padding-x: 0;
